/*
* @Author: liuzhipeng
* @Date: 2021-07-12 18:06:08
 * @Last Modified by: liuzhipeng
 * @Last Modified time: 2021-07-20 17:03:06
*/
<template>
  <div class="content">
    <el-row class="listHead" v-if="goodsFreeMail.length > 0">
      <el-col :span="2">
        <label>
          <input
            type="checkbox"
            :checked="freeMailSelected"
            @change="goodsFreeMailSelected()"
          /><span class="text">小额包邮</span>
        </label>
      </el-col>
    </el-row>
    <div class="goods" v-for="(item, index) in goodsFreeMail" :key="index">
      <el-col :span="2">
        <label>
          <input
            type="checkbox"
            :disabled="!item.product.publishStatus"
            :checked="item.flag"
            @change="handleGoodsFreeMailChange(index)"
          />
        </label>
      </el-col>
      <el-col class="goodsImg" :span="10">
        <ShopImg :msg="item"  :Datas="itemDatasArr"></ShopImg>
      </el-col>
      <el-col class="goodsPrice text-center" :span="3">{{
        item.product.price | capitalize
      }}</el-col>
      <el-col class="num text-center" :span="3">
        <div class="wraper" v-if="item.product.publishStatus">
          <button
            type="button"
            class="reduce text-center"
            @click="handleReducerFreeMail(index)"
          >
            -
          </button>
          <input
            type="text"
            v-model="item.quantity"
            :max="item.product.stockNum"
            :min="item.product.minOrderNum"
            :step="item.product.minOrderNum"
            @change="handleChangValueFreeMail(index)"
            ref="input"
          />
          <button
            type="button"
            class="increase text-center"
            @click="handleAddFreeMail(index)"
          >
            +
          </button>
        </div>
        <div class="wrapers" v-else>
          <span class="text-center">{{ item.quantity }}</span>
        </div>
      </el-col>
      <el-col class="Subtotal text-center" :span="3">{{
        ((item.quantity * (item.product.price * 10000)) / 10000).toFixed(2)
          | capitalize
      }}</el-col>
      <el-col :span="3" class="text-center">
        <div class="operation text-center" @click="handleDelFreeMail(index)">
          删除
        </div>
      </el-col>
    </div>
  </div>
</template>

<script>
/**
 * !该组件用于展示小额包邮商品的购物车
 */
const ShopImg = () => import("components/common/ShopImg.vue");
import { mapState, mapMutations, mapActions } from "vuex";
export default {
  name: "CartBuyInfoFreeMail",
  data() {
       return {
      itemDatasArr:[],
    };
  },
  watch:{
    'itemDatas':function(){
         this.itemDatasArr=this.itemDatas
        //  alert(this.itemDatasArr)
      },
  },
  props:{
    itemDatas:{
       type:Array
    }
  },
  computed: {
    ...mapState({
      goodsFreeMail: (state) => state.goodsFreeMail,
      freeMailSelected: (state) => state.freeMailSelected,
    }),
  },
  methods: {
    ...mapMutations({
      handleGoodsFreeMailChange: "handleGoodsFreeMailChange",
      handleReducerFreeMail: "handleReducerFreeMail",
      handleAddFreeMail: "handleAddFreeMail",
      handleChangValueFreeMail: "handleChangValueFreeMail",
      goodsFreeMailSelected: "goodsFreeMailSelected",
    }),
    ...mapActions({
      handleDelFreeMail: "handleDelFreeMail",
    }),
  },
  created() {},
  components: {
    ShopImg,
  },
};
</script>

<style lang="less" scoped>
@import "~style/index.less";

[v-cloak] {
  display: none;
}

.listHead {
  color: #666666;
  font-size: 13px;
  line-height: 18px;
  margin-left: 10px;
  margin-top: 20px;
  margin-bottom: 12px;

  .text {
    margin-left: 10px;
  }
}

.goods {
  width: 1198px;
  display: flex;
  align-items: center;
  border-left: 1px solid #f0f0f0;
  border-right: 1px solid #f0f0f0;
  border-bottom: 1px solid #f0f0f0;
  padding-top: 15px;
  padding-bottom: 15px;
  position: relative;
  background: #f9f9f9;
  .goodsPrice {
    font-size: 14px;
  }
  .Subtotal {
    font-weight: bold;
    font-size: 14px;
  }
  // .abBg{
  //   position:absolute;
  //   left: 0;
  //   top: 0;
  //   width: 100%;
  //   height: 100%;
  //   display: flex;
  //   justify-content: center;
  //   align-items: center;
  //  background: #c1bfbf;
  //   color: #000;
  //   font-size: 30px;
  //   font-weight: bold;
  //   opacity: 0.3;
  //   z-index: 1;
  // }
  .operation {
    cursor: pointer;
    font-size: 12px;

    &:hover {
      color: #ff3333;
    }
  }

  input {
    cursor: pointer;
  }

  & label {
    padding-left: 10px;
  }

  .wrapers {
    width: 110px;
    height: 36px;
    background: #f6f6f6;

    span {
      text-align: center;
      width: 100%;
      line-height: 36px;
    }
  }

  .wraper {
    width: 110px;
    height: 28px;
    background: #fff;
    display: flex;
    align-items: center;
    color: #666;

    & > button {
      width: 16px;
      padding: 0 20px;
      height: 28px;
      line-height: 28px;
      display: flex;
      color: #666;
      justify-content: center;
      align-items: center;
      background: #f0f0f0;
      font-size: 15px;
      // border: 1px solid #F0F0F0;
      border: none;
      cursor: pointer;
    }

    input {
      display: inline-block;
      width: 70px;
      height: 28px;
      background: #fff;
      text-align: center;
      // margin-left: 4px;
      // margin-right: 4px;
      border: none;
      font-size: 14px;
      cursor: pointer;

      //  border: 1px solid #F0F0F0;
    }
  }
}
</style>